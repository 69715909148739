body {
    font-family: 'Source Sans Pro', sans-serif;
}

.main_container {
    /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%230d6efd' fill-opacity='1' d='M0 128L40 138.7C80 149 160 171 240 165.3C320 160 400 128 480 122.7C560 117 640 139 720 128C800 117 880 75 960 85.3C1040 96 1120 160 1200 192C1280 224 1360 224 1400 224L1440 224L1440 0L1400 0C1360 0 1280 0 1200 0C1120 0 1040 0 960 0C880 0 800 0 720 0C640 0 560 0 480 0C400 0 320 0 240 0C160 0 80 0 40 0L0 0Z'%3E%3C/path%3E%3C/svg%3E"); */
    background-repeat: no-repeat;
}

p.emptyList {
    background-color: yellow;
    padding: 30px;
    font-size: 20px;
    border-radius: 50px;
    background: #ffffff;
    box-shadow: inset 20px 20px 60px #d9d9d9,
        inset -20px -20px 60px #ffffff;
}

.custom_hover:hover {
    filter: brightness(95%);
}

.custom_hover_more_details:hover {
    filter: brightness(80%);
    text-decoration: underline;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.logo {
    position: relative;
}
